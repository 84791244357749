<template>
    <div id="loginAdmin">
        <div class="content">
            <div class="nav_top">
                <span>AI质控数据监测系统</span>
            </div>
            <div style="margin-top: 40px;">
                <avue-login :option="option" @submit="submit" @send="sendCode" v-model="form"></avue-login>
                <!-- <form id="myform">
                    <div class="login">
                        <input type="text" v-model="login.loginName" autocomplete="off" placeholder="请输入登录名" id="account" />
                    </div>
                    <div class="login">
                        <input type="password" v-model="login.passwd" autocomplete="off" placeholder="请输入密码" id="password" />
                    </div>
                </form> -->

            </div>
            <!-- <div class="content_decoration">
                <dv-decoration-5 style="width:300px;height:40px;" />
            </div>
            <div class="left">
                <p class="text-effect">
                    <span>A</span><span>I</span>
                </p>
                <p>质控统计</p>
                <div id="earth">
                    <div class="map">
                        <div class="map1"><img src="../assets/lbx.png"></div>
                        <div class="map2"><img src="../assets/jt.png"></div>
                        <div class="map3"><img src="../assets/map.png"></div>
                    </div>
                </div>
            </div>
            <div class="middle">
                <form id="myform">
                    <div class="login">
                        <input type="text" v-model="login.loginName" autocomplete="off" placeholder="请输入登录名" id="account" />
                    </div>
                    <div class="login">
                        <input type="password" v-model="login.passwd" autocomplete="off" placeholder="请输入密码" id="password" />
                    </div>
                </form>

            </div>
            <div class="right">
                <div id="btn" title="Login" @click="onLogin">
                    <div :class="['btn0', Dclass]"></div>
                    <div class="btn1"></div>
                    <div  :class="['btn2', Dclass]"></div>
                </div>
            </div> -->
        </div>
        <div class="warning" v-if="warning">
            <span>×</span>{{warningText}}
        </div>
        <el-dialog
            title="请输入验证码"
            custom-class="code-dialog"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="20%"
            >
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <el-input class="code_input" size="mini" v-model="picVerifyCodeValue" placeholder="请输入验证码"></el-input>
                <el-image
                    class="verifyCode-img"
                    @click="refresh"
                    v-if="verifyCode && verifyCode.picVerifyCode64"
                    style="height: 45px"
                    :src="verifyCode && verifyCode.picVerifyCode64"
                    fit="contain"></el-image>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" size="mini" @click="mobileVerify">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {ajax as $ajax} from "@/utils/libs/utils.http.js";
export default {
    data () {
        var phoneTest = (rule, value, callback)=>  {
            let reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
            if (value === '') {
                callback(new Error('请输入手机号'));
            } else if (reg.test(value)) {
                callback();
            } else {
                callback(new Error('请输入正确手机号'));
            }
        };
        return {
            timers: null,
            form: {
            },
            warning: false,
            warningText: 'PASSWORD ERROR, PLEASE RE-ENTER PASSWORD',
            canvasShow: true,
            Dclass: '',
            option: {
                width: 400,
                time: 60,
                codeType: 'phone',
                // codeType: 'phone', //phone为手机验证码/img为图片验证码
                column: {
                    username: {
                        // hide: true,
                        label: '手机号',
                        prop: 'phone',
                        placeholder:'请输入手机号',
                        prefixIcon: 'el-icon-mobile-phone',
                        autocomplete: 'off',
                        rules: { required: true, validator: phoneTest, trigger: 'blur' }
                    },
                    password: {
                        hide: true,
                        label: '密码',
                        prop: 'password',
                        // placeholder:'',
                        autocomplete: 'off',
                        rules: { required: true, message: '请输入密码', trigger: 'blur' }
                    },
                    code: {
                        label: '验证码',
                        prop: 'code',
                        // tip: '点击图片可以刷新验证码',
                        autocomplete: 'off',
                        rules: { required: true, message: '请输入验证码', trigger: 'blur' }
                    }
                }
            },
            verifyCode: undefined,
            dialogVisible: false,
            picVerifyCodeValue: ''
        }
    },
    components: {
    },
    beforeDestroy () {
        // clearInterval(this.timers);
    },
    mounted() {
        // this.canvasStart()
        // this.timers =  setInterval(() => {
        //     this.canvasShow = false
        //     this.canvasStart()
        // }, 15000)
        
    },
    methods: {
        sendCode(done) {
            if (this.form.username) {
                let reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
                if (reg.test(this.form.username)) {
                    this.dialogVisible = true
                    this.getVerifyCode()
                    done();
                    // this.$message.success(this.form.username+'验证码发送成功')
                } else {
                    this.$message.error('请输入正确手机号')
                }
            } else {
                this.$message.error('请输入手机号')
            }
        },
        refresh() {
            this.getVerifyCode()
            this.$message.success('验证码刷新成功')
        },
        // 登录手机验证码
        mobileVerify() {
            if (this.picVerifyCodeValue !== '') {
                $ajax({
                    type: "get",
                    dataType: "json",
                    url : '/v1/login/mobile-verify-code',
                    data: {
                        mobile: this.form.username,
                        picVerifyCodeId: this.verifyCode.picVerifyCodeId,
                        picVerifyCodeValue: this.picVerifyCodeValue
                    }
                }).then(res => {
                    if (res.code === 200) {
                        if(res.data) {
                            this.dialogVisible = false
                            this.$message.success(this.form.username+' 验证码发送成功')
                        }
                        console.log('', res);
                        // if (res.result) {
                        // }
                    } else {
                        this.$message(res.errMsg)
                    }
                })
            } else {
                this.$message.error('请输图形入验证码')
            }
        },
        // 获取图片验证码
        getVerifyCode() {
            $ajax({
                type: "POST",
                dataType: "json",
                url : '/v1/login/pic-verify-code',
            }).then(res => {
                if (res.code === 200) {
                    res.data.picVerifyCode64 = `data:image/jpg;base64,${res.data.picVerifyCode64}`
                    this.verifyCode = res.data
                } else {
                    this.$message(res.errMsg)
                }
            })
        },
        allUrl (url, params) {
            return  $ajax({
                type: "POST",
                dataType : "json",
                url : url,
                data: params
            })
        },
        async submit(form) {
            let {data, code, errMsg} =  await this.allUrl('/v1/login/mobile-verify-code',{
                mobile: form.phone,
                verifyCode: form.code,
            })
            if (code === 200) {
                sessionStorage.setItem('uetk', data.token);
                this.$router.replace("/img");
            } else {
                this.$message(errMsg)
            }
        },
        async onLogin() {
            if (this.login.name !== '' && this.login.pwd !== '') {
                this.Dclass = 'loginSumibt'
                this.warning = false
                let {data, code, errMsg} =  await this.allUrl('/v1/login/pwd',this.login)
                if (code === 200) {
                    sessionStorage.setItem('uetk', data.token);
                    this.$router.replace("/img");
                } else {
                    this.warning = true
                    this.Dclass = ''
                    this.warningText = errMsg
                }
            } else {
                this.warning = true
                this.warningText = 'PASSWORD ERROR, PLEASE RE-ENTER PASSWORD'
            }
        },
    }
};
</script>
<style>
    #loginAdmin .el-form-item__label {
        color: #FFFFFF !important;
    }
    #loginAdmin .avue-login__submit {
        background: #FFFFFF;
        border-radius: 31px;
        color: #E94E46;
        border: none;
        margin-top: 40px;
    }
    #loginAdmin .el-input__inner {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 21px;
        border: none;
    }
    #loginAdmin .el-form-item__error {
        color: #FFFFFF;
    }
    #loginAdmin .el-input__inner::-webkit-input-placeholder {
        color: #FFFFFF;
    }
    #loginAdmin .el-input__prefix, .el-input__suffix {
        color: #FFFFFF;
    }
    #loginAdmin .el-input .el-input__clear {
        color: #FFFFFF;
    }
    #loginAdmin .el-form-item__label {
        display: none;
    }
    .code_input .el-input__inner::-webkit-input-placeholder {
        color: #aab2bd !important;
    }
    .code-dialog {
    }
    .verifyCode-img {
    }
</style>
<style lang="scss" scoped>
// $box-height: 520px;
// $box-width: 100%;
.code_input {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 21px;
    border: 1px solid;
    margin-right: 10px;
}
#loginAdmin {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-image: url("../assets/bg_login.png");
    background-size: cover;
    background-position: center center;
    // background-image: -webkit-radial-gradient(ellipse farthest-corner at center center, #1b44e4 0%, #020f3a 100%);
    // background-image: radial-gradient(ellipse farthest-corner at center center, #1b44e4 0%, #020f3a 100%);
    cursor: move;
}

body {
    // background: url("../assets/pageBg.png") no-repeat center;
    // background-size: cover;
}

.content {
    width: 450px;
    height: 50%;
    // margin: 0 auto;
    position: absolute;
    z-index: 2;
    top: 20%;
    right: 10%;
    .nav_top {
        text-align: center;
        font-size: 36px;
        font-family: YouSheBiaoTiYuan;
        color: #FFFFFF;
        line-height: 92px;
    }
    // margin-top: -125px;
    // background: url("../assets/login0.png") no-repeat center;
    // .content_decoration {
    //     position: absolute;
    //     right: 0;
    //     transform: rotate(180deg);
    // }
}

.left,
.middle,
.right {
    width: 100%;
    float: left;
    color: #B0CC35;
    text-align: center;
}

.left {
    margin-left: 1%;
    margin-top: 13px;

    .text-effect {
        color: #ffff00;
        font-family: 'Cabin Sketch', cursive;
        font-size: 100px;
        text-align: center;
        text-transform: uppercase;
        margin: 0 auto;
        position: relative;
        top: -25px;
        left: -40px;
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
    }

    & p:nth-child(2) {
        color: #ffff00;
        font-family: 'Cabin Sketch', cursive;
        font-size: 25px;
        text-align: center;
        text-transform: uppercase;
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
    }

    .text-effect span {
        display: inline-block;
        animation: animate 0.5s linear infinite both;
    }

    .text-effect span:nth-child(1),
    .text-effect span:nth-child(4),
    .text-effect span:nth-child(7),
    .text-effect span:nth-child(10) {
        color: #4cd137;
    }

    .text-effect span:nth-child(2),
    .text-effect span:nth-child(5),
    .text-effect span:nth-child(8) {
        color: #ff0000;
    }

    @keyframes animate {

        0%,
        50%,
        100% {
            transform: rotate(0deg) scale(1);
        }

        25% {
            transform: rotate(4deg) scale(0.98);
        }

        75% {
            transform: rotate(-4deg) scale(1.02);
        }
    }

    @media only screen and (max-width: 990px) {
        .text-effect {
            font-size: 100px;
        }
    }

    @media only screen and (max-width: 767px) {
        .text-effect {
            font-size: 80px;
        }
    }

    @media only screen and (max-width: 479px) {
        .text-effect {
            font-size: 60px;
        }
    }

    @media only screen and (max-width: 359px) {
        .text-effect {
            font-size: 45px;
        }
    }
}

.left p {
    font-size: 18px;
    // padding-top: 15px;
}

.middle {
    margin-top: 80px;
}

.middle .login {
    width: 280px;
    height: 40px;
    margin-bottom: 30px;
    // background: url("../assets/login1.png") no-repeat center;
}

.middle .login input {
    height: 38px;
    border: 1px solid #666;
    width: 100%;
    background: none !important;
    outline: none;
    color: #B0CC35;
    border-radius: 5px;
}

.right {
    margin-top: 50px;
    position: relative;
    box-sizing: border-box;
    padding-top: 88px;
    padding-left: 156px;
}
.loginSumibt {
    animation: clockwise 0.5s linear infinite !important;
}
.btn0 {
    position: absolute;
    width: 78px;
    height: 78px;
    background: url("../assets/close0.png") no-repeat;
    background-size: 100%;
    animation: clockwise 3s linear infinite;
}

.btn1 {
    position: absolute;
    width: 52px;
    height: 52px;
    margin: 13px;
    background: url("../assets/close1.png") no-repeat;
    background-size: 100%;
}

.btn2 {
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 24px;
    background: url("../assets/close2.png") no-repeat;
    background-size: 100%;
    animation: anticlockwise 3s linear infinite;
}

@keyframes clockwise {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

@keyframes anticlockwise {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes myfirst2 {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

@keyframes myfirst {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-359deg);
    }
}

#earth {
    width: 150px;
    height: 150px;
    margin-left: 60px;
    margin-top: 15px;
    position: relative;

    .map {
        width: 150px;
        height: 150px;
        position: relative;
        z-index: 9;

        img {
            width: 100%;
            height: 100%;
            -webkit-user-select:none;
            -moz-user-select:none;
            -ms-user-select:none;
            user-select:none;
        }
    }

    .map1,
    .map2,
    .map3 {
        position: absolute;
        opacity: 0.7
    }

    .map1 {
        width: 150px;
        height: 150px;
        z-index: 2;
        animation: myfirst2 15s infinite linear;
    }

    .map2 {
        width: 150px;
        height: 150px;
        z-index: 3;
        opacity: 0.2;
        animation: myfirst 10s infinite linear;
    }

    .map3 {
        width: 150px;
        height: 150px;
        z-index: 1;
    }
}

.warning {
    width: 595px;
    height: 100px;
    background: url("../assets/login2.png") no-repeat center;
    margin: 0 auto;
    position: relative;
    color: red;
    line-height: 100px;
    text-align: center;
    font-weight: bold;
}

.warning span {
    animation: 1s bounceIn;
    font-size: 50px;
    display: block;
    position: absolute;
    left: 8%;
    margin-top: -5px;
}

@keyframes bounceIn {

    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

.bounceIn {
    animation: 500ms bounceIn;
}

#btn {
    width: 78px;
    height: 78px;
    cursor: pointer;
}
</style>